// import fetch from "isomorphic-unfetch";

import reduxApiMiddlewareBroker from "redux-api-middleware-broker";
import cookie from "react-cookies";
// import cookieParser from "cookie-parser";

const BASE_URL = process.env.API_URL;

const noop = () => {};

const storeTokenToCookie = (token) => {
  const expires = new Date();
  // Expires in the next 24 hours
  expires.setDate(Date.now() + 3600000 * 24);
  if (cookie.load("authToken")) {
    cookie.save("authToken", token, {
      expires,
      path: "/",
      // ...(env !== "development" ? { domain: ".datingkinky.com" } : {})
    });
  }
};

const action = (
  opts = { url: "", types: [], method: "GET", data: {} },
  fileUpload = false,
  onRequestComplete = noop,
  preprocessResult = noop
) => {
  // Set the headers so we don't have to keep setting it evertime we need to call the api
  const token = cookie.load("authToken") || "";
  const needConfirm = cookie.load("needRegConfirmation") || "";
  const _opts = {
    ...opts,
    body: opts.body,
    endpoint: opts.custom
      ? opts.url
      : `${BASE_URL}${opts.url || opts.endpoint}`,
    headers: {
      ...opts.headers,
      ...(token && !opts.custom && !needConfirm
        ? { Authorization: `${token}` }
        : {}),
      ...(fileUpload
        ? {}
        : {
            Accept: "application/json",
            "Content-Type": "application/json",
          }),
    },
  };
  delete _opts.url;
  return reduxApiMiddlewareBroker(
    _opts,
    fileUpload,
    (action, state, response) => {
      // Some manipulation to store the header
      if (response) {
        const authToken = response.headers.get("x-auth-token");
        if (authToken) {
          storeTokenToCookie(authToken);
        }
      }
      return onRequestComplete(action, state, response);
    },
    // We might need to manipulate the returned data in the future
    (json) => preprocessResult(json) || json
  );
};
export default action;
