import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getMessages = (parentId = "", query = {}, previousData = []) => {
  const _query = queryString.stringify(query);
  return buildRSA(
    {
      endpoint: `/messages/${parentId}?${_query}`,
      method: "GET",
      types: [
        types.DO_GET_MESSAGES,
        types.SET_GET_MESSAGES_DATA,
        types.SET_GET_MESSAGES_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const updateMessage = (messageId, data) =>
  buildRSA(
    {
      endpoint: `/messages/${messageId}`,
      method: "PATCH",
      types: [
        types.DO_PATCH_MESSAGE,
        types.SET_PATCH_MESSAGE_DATA,
        types.SET_PATCH_MESSAGE_ERROR
      ],
      body: data
    },
    true,
    false
  );

export const deleteMessage = messageId =>
  buildRSA(
    {
      endpoint: `/messages/${messageId}`,
      method: "DELETE",
      types: [
        types.DO_DELETE_MESSAGE,
        types.SET_DELETE_MESSAGE_DATA,
        types.SET_DELETE_MESSAGE_ERROR
      ]
    },
    true,
    false
  );

export default {
  getMessages,
  updateMessage,
  deleteMessage
};
