import auth from "./auth";
import app from "./app";
import photos from "./photos";
import users from "./users";
import optionValues from "./optionValues";
import sitePosts from "./sitePosts";
import discussions from "./discussions";
import writings from "./writings";
import media from "./media";
import category from "./category";
import role from "./role";
import comments from "./comments";
import conversations from "./conversations";
import messages from "./messages";
import subscriptions from "./subscriptions";
import tags from "./tags";
import reports from "./reports";
import promoCode from "./promoCode";
import emailNotification from "./emailNotifications";
import plusBooks from "./plusBooks";
import file from "./file";
import currentUser from "./currentUser";
import bannedMessage from "./bannedMessage";
import chat from "./chat";
import inquiry from "./inquiry";
import vbPabConnect from "./vbPabConnect";
import pabAcadleTag from "./pabAcadleTag";
import bannedProfileDet from "./bannedProfileDet";
import bannedUsernameChar from "./bannedUsernameChar";
import navBooks from "./navBooks";
import navCommunities from "./navCommunities";
import navEvents from "./navEvents";
import upcoachUsersToDel from "./upcoachUsersToDel";

export const authActions = auth;
export const appActions = app;
export const photosActions = photos;
export const usersActions = users;
export const optionValuesActions = optionValues;
export const sitePostsAction = sitePosts;
export const writingsAction = writings;
export const discussionsAction = discussions;
export const mediaAction = media;
export const categoryActions = category;
export const roleActions = role;
export const commentsActions = comments;
export const conversationsActions = conversations;
export const messagesActions = messages;
export const subscriptionsActions = subscriptions;
export const tagsActions = tags;
export const reportsActions = reports;
export const promoCodeActions = promoCode;
export const emailNotificationsActions = emailNotification;

export const fileActions = file;
export const currentUserActions = currentUser;

export const chatActions = chat;
export const plusBooksActions = plusBooks;
export const bannedMessActions = bannedMessage;
export const inquiryActions = inquiry;
export const vbPabblyConnectActions = vbPabConnect;
export const pabAcadleTagActions = pabAcadleTag;
export const bannedProfileDetActions = bannedProfileDet;
export const bannedUsernameCharActions =bannedUsernameChar;

export const navBooksActions = navBooks;
export const navEventsActions = navEvents;
export const navCommunitiesActions = navCommunities;

export const upcoachUsersToDelActions = upcoachUsersToDel;