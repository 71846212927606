export default {
    dailyQs: {
      data: {
        total: 0,
        skip: 0,
        limit: 10,
        page: 1,
        data: []
      },
      error:{}
    },
    boundariesUsers:{
        data:{}, 
        error:{}
    }
}