import types from "../types";
import state from "../defaultState";
import { fromJS } from "immutable";

const initialState = state.currentUser;
export default function app(state = initialState, action) {
  switch (action.type) {
    case types.SET_CURRENT_USER_DATA:
      return state.setIn(["data"], fromJS(action.payload.data));
    case types.SET_USER_PROFILE_VIEWERS_DATA:
      return state.setIn(["profileViewers"], fromJS(action.payload));
    case types.SET_TOTAL_UNREAD_CONVERSATION:
      return state.setIn(["totalUnreadConversation"], fromJS(action.payload));
    case types.SET_USER_CONVERSATION_LIST:
      return state.setIn(["conversationList"], fromJS(action.payload));
    default:
      return state;
  }
}
