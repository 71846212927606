import types from "../types";
import buildRSA from "./buildRSA";
import __api from "./__api"
export const uploadFile = data => {
  return buildRSA(
    {
      endpoint: "/files",
      method: "POST",
      types: [
        types.DO_FILE_UPLOAD,
        types.SET_FILE_UPLOAD_DATA,
        types.SET_FILE_UPLOAD_ERROR
      ],
      body: data
    },
    true,
    true
  );
};

const getUploadUrl = (purpose = "Site Photo", filetype, mediaType) => {
  return buildRSA(
    {
      endpoint: `/files/upload-url/${purpose}?filetype=${filetype}&mediaType=${
        mediaType || ""
      }`,
      method: "GET",
      types: [
        "DO_GET_UPLOAD_URL",
        "SET_GET_UPLOAD_URL_DATA",
        "SET_GET_UPLOAD_URL_ERROR",
      ],
    },
    true,
    true
  );
};


export const uploadFileToS3 = (data, dataType, url) => {
  return __api(
    {
      url,
      custom: true,
      method: "PUT",
      types: [
        "DO_S3_FILE_UPLOAD",
        "SET_S3_FILE_UPLOAD_DATA",
        "SET_S3_FILE_UPLOAD_ERROR",
      ],
      body: data,
      headers: {
        "Content-Type": dataType,
        Accepts: "application/json",
      },
    },
    true
  );
};

export default {
  uploadFile,
  getUploadUrl,
  uploadFileToS3
};
