import types from "../types";
import buildRSA from "./buildRSA";

export const setAuthState = (state = false) => {
  return {
    payload: state,
    type: types.SET_AUTH_STATE
  };
};

export const loginUser = data => {
  return buildRSA(
    {
      endpoint: "/authenticate",
      method: "POST",
      types: [types.DO_USER_LOGIN, types.SET_LOGIN_DATA, types.SET_LOGIN_ERROR],
      body: data
    },
    false
  );
};

export default {
  setAuthState,
  loginUser
};
