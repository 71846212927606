import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getOptionValues = (optionId, query = {}, previousData = []) => {
  const _query = queryString.stringify(query);
  return buildRSA({
    endpoint: `/options/${optionId}/values?${_query}`,
    method: "GET",
    types: [
      types.DO_GET_OPTION_VALUE,
      types.SET_GET_OPTION_VALUE_DATA,
      types.SET_GET_OPTION_VALUE_ERROR
    ]
  });
};

export const addOptionValue = (optionId, data) =>
  buildRSA(
    {
      endpoint: `/options/${optionId}/values/`,
      method: "POST",
      types: [
        types.DO_POST_OPTION_VALUE,
        types.SET_POST_OPTION_VALUE_DATA,
        types.SET_POST_OPTION_VALUE_ERROR
      ],
      body: data
    },
    true,
    false
  );

export const updateOptionValue = (optionId, optionValueId, data) =>
  buildRSA(
    {
      endpoint: `/options/${optionId}/values/${optionValueId}`,
      method: "PATCH",
      types: [
        types.DO_PATCH_OPTION_VALUE,
        types.SET_PATCH_OPTION_VALUE_DATA,
        types.SET_PATCH_OPTION_VALUE_ERROR
      ],
      body: data
    },
    true,
    false
  );

export const deleteOptionValue = (optionId, optionValueId) =>
  buildRSA(
    {
      endpoint: `/options/${optionId}/values/${optionValueId}`,
      method: "DELETE",
      types: [
        types.DO_DELETE_OPTION_VALUE,
        types.SET_DELETE_OPTION_VALUE_DATA,
        types.SET_DELETE_OPTION_VALUE_ERROR
      ]
    },
    true,
    false
  );

export default {
  addOptionValue,
  updateOptionValue,
  deleteOptionValue,
  getOptionValues
};
