import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getSitePosts = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);
  return buildRSA(
    {
      endpoint: `/site-posts?${_query}`,
      method: "GET",
      types: [
        types.DO_GET_SITE_POSTS_DATA,
        types.SET_GET_SITE_POSTS_DATA,
        types.SET_GET_SITE_POSTS_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const updateSitePost = (sitePostId, data) =>
  buildRSA(
    {
      endpoint: `/site-posts/${sitePostId}`,
      method: "PATCH",
      types: [
        types.DO_PATCH_SITE_POST,
        types.SET_PATCH_SITE_POST_DATA,
        types.SET_PATCH_SITE_POST_ERROR
      ],
      body: data
    },
    true,
    false
  );

export const deleteSitePost = sitePostId =>
  buildRSA(
    {
      endpoint: `/site-posts/${sitePostId}`,
      method: "DELETE",
      types: [
        types.DO_DELETE_SITE_POST,
        types.SET_DELETE_SITE_POST_DATA,
        types.SET_DELETE_SITE_POST_ERROR
      ]
    },
    true,
    false
  );

export const createSitePost = data =>
  buildRSA(
    {
      endpoint: `/site-posts`,
      method: "POST",
      types: [
        types.DO_POST_SITE_POST,
        types.SET_POST_SITE_POST_DATA,
        types.SET_POST_SITE_POST_ERROR
      ],
      body: data
    },
    true,
    false
  );

export default {
  getSitePosts,
  updateSitePost,
  deleteSitePost,
  createSitePost
};
