import types from "../types";
import state from "../defaultState";

const initialState = state.auth;
export default function app(state = initialState, action) {
  switch (action.type) {
    case types.SET_AUTH_STATE:
      return state.setIn(["status"], action.payload);
    default:
      return state;
  }
}
