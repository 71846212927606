import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getBannedMessages = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);
  return buildRSA(
    {
      endpoint: `/chatInvestigation/bannedMess?${_query}`,
      method: "GET",
      types: [
        types.GET_BANNED_MESSAGES,
        types.GET_BANNED_MESSAGES_SUCCESS,
        types.GET_BANNED_MESSAGES_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const updateMessage = (tagId, data) =>
  buildRSA(
    {
      endpoint: `/chatInvestigation/bannedMess/${tagId}`,
      method: "PATCH",
      types: [
        types.UPDATE_BANNED_MESSAGE,
        types.UPDATE_BANNED_MESSAGE_SUCCESS,
        types.UPDATE_BANNED_MESSAGE_ERROR
      ],
      body: data
    },
    true,
    false
  );

export const deleteMessage = tagId =>
  buildRSA(
    {
      endpoint: `/chatInvestigation/bannedMess/${tagId}`,
      method: "DELETE",
      types: [
        types.DELETE_BANNED_MESSAGE,
        types.DELETE_BANNED_MESSAGE_SUCCESS,
        types.DELETE_BANNED_MESSAGE_ERROR
      ]
    },
    true,
    false
  );

export const createNewBannedMess = data =>
  buildRSA(
    {
      endpoint: `/chatInvestigation/bannedMess`,
      method: "POST",
      types: [
        types.CREATE_BANNED_MESSAGE,
        types.CREATE_BANNED_MESSAGE_SUCCESS,
        types.CREATE_BANNED_MESSAGE_ERROR
      ],
      body: data
    },
    true,
    false
  );

  export const adminMessage = type =>
  buildRSA(
    {
      endpoint: `/adminChat/autoMessage?type=${type}`,
      method: "GET",
      types: [
        types.GET_ADMIN_MESSAGE,
        types.GET_ADMIN_MESSAGE_SUCCESS,
        types.GET_ADMIN_MESSAGE_ERROR
      ],      
    },
    true,
    false
  );

  export const updtAdminMessage = data =>
  buildRSA(
    {
      endpoint: `/adminChat/updtAutoMessage`,
      method: "POST",
      types: [
        types.UPDATE_ADMIN_MESSAGE,
        types.UPDATE_ADMIN_MESSAGE_SUCCESS,
        types.UPDATE_ADMIN_MESSAGE_ERROR
      ],
      body: data
    },
    true,
    false
  );

export default {
  createNewBannedMess,
  updateMessage,
  deleteMessage,
  getBannedMessages,
  updtAdminMessage,
  adminMessage
};
