import types from "../types";
import state from "../defaultState";
import { fromJS } from "immutable";
const initialState = state.app;
export default function app(state = initialState, action) {
  switch (action.type) {
    case types.SET_APP_STATE:
      return state.setIn(["status"], action.payload);
    case types.SET_APP_LOCALE: // Language file to use!!!
      return state.setIn(["locale"], action.payload);
    case types.SET_APP_ROUTE_DATA: // Language file to use!!!
      return state.setIn(["route"], action.payload);
    case types.SET_OPTION_LIST:
      return state.setIn(["optionList"], fromJS(action.payload));
    case types.SET_ROLES:
      return state.setIn(["roles"], fromJS(action.payload.data));
    case types.SET_ACCOUNT_TYPES:
      return state.setIn(["accountTypes"], fromJS(action.payload.data));
    default:
      return state;
  }
}
