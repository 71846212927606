export default {
  app: {
    status: "01",
    locale: "en",
    route: {
      path: "/",
      resolved: true
    },
    optionList: {},
    roles: [],
    accountTypes: []
  },
  reg: {
    userData: {},
    regError: { type: "", msg: "" },
    verifyRegErr: { type: "", msg: "" },
    verifyRegData: {}
  },
  auth: {
    state: false,
    token: ""
  },
  currentUser: {
    data: {},
    profileViewers: { data: [] },
    totalUnreadConversation: 0
  },
  conversationList: {
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 1,
      data: []
    },
    error: {}
  },
  user: {
    data: {},
    error: {}
  },
  users: {
    data: {},
    error: {}
  },
  subscriptions: {
    data: {},
    error: {}
  },
  tags: {
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 1,
      data: []
    },
    error: {}
  },

  reports: {
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 1,
      data: []
    },
    error: {}
  },
  chatWindow: {
    messages: {},
    isTyping: false,
    isOpened: false,
    profile: { username: "", avatar: "/static/img/avatar/small.png" },
    chatId: "",
    totalUnread: 0,
    status: "",
    isLoading: false,
    totalAPIMessages: 0
  },
  categories: {
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 1,
      data: []
    },
    error: {}
  },
  photos: {
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 0,
      data: []
    },
    error: {}
  },
  // photoVerifs: {
  //   data: {
  //     total: 0,
  //     skip: 0,
  //     limit: 10,
  //     page: 0,
  //     data: []
  //   },
  //   error: {}
  // },
  sitePosts: {
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 0,
      data: []
    },
    error: {}
  },
  writings: {
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 0,
      data: []
    },
    error: {}
  },
  discussions: {
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 0,
      data: []
    },
    error: {}
  },
  media: {
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 0,
      data: []
    },
    error: {}
  },
  roles: {
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 0,
      data: []
    },
    error: {}
  },
  promoCodes: {
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 0,
      data: []
    },
    error: {}
  },
  emailNotifications: {
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 0,
      data: []
    },
    error: {}
  },
  comments: {
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 0,
      data: []
    },
    error: {}
  },

  conversations: {
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 0,
      data: []
    },
    error: {}
  },
  messages: {
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 0,
      data: []
    },
    error: {}
  },
  chat: {
    data:{},
    isReady:false,
    error:false
  },
  
  plusBooks: {
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 1,
      data: []
    },
    error: {}
  },

  bannedMessage: {
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 1,
      data: []
    },
    error: {}
  },

  inquiry:{
    mainTopic:{},
    data:{},
    error:{}
  },

  vbPabConnect:{
    vboutList:{},
    products:{},
    data:{},
    error:{}
  },

  vbPabProdTag:{
    vboutList:{},
    products:{},
    data:{},
    error:{}
  },
  
  pabAcadleTag:{
    acadleTags:{},
    products:{},
    data:{},
    error:{}
  },

  bannedProfileDet:{
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 1,
      data: []
    },
    error: {}
  },

  bannedUsernameChar:{
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 1,
      data: []
    },
    error: {}
  },
  
  navBooks:{
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 1,
      data: []
    },
    error: {}
  },

  navEvents:{
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 1,
      data: []
    },
    error: {}
  },

  navCommunities:{
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 1,
      data: []
    },
    error: {}
  },

  upcoachUsersToDel:{
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 1,
      data: []
    },
    error: {}

  },
  dailyQs: {
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 1,
      data: []
    },
    error:{}
  },
  qResponses:{
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 1,
      data: []
    },
    error:{}
  },
  userResponses:{
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 1,
      data: []
    },
    error:{}
  },
  boundariesUsers:{
    data:{}, 
    error:{}
  },
  boundUser:{
    data: {}
  },
  boundReports: {
    data: {
      total: 0,
      skip: 0,
      limit: 10,
      page: 1,
      data: []
    },
    error: {}
  },



};
