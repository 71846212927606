import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getReports = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);
  return buildRSA(
    {
      endpoint: `/reports?${_query}`,
      method: "GET",
      types: [
        types.DO_GET_REPORTS,
        types.SET_GET_REPORTS_DATA,
        types.SET_GET_REPORTS_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};


export const clearReports=(data)=> async(dispatch)=>{
  return dispatch({type:types.SET_GET_REPORTS_DATA, payload: data})
 }

export const getSystemUserReports = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);
  return buildRSA(
    {
      endpoint: `/reports/systemUserReport?${_query}`,
      method: "GET",
      types: [
        types.DO_GET_REPORTS,
        types.SET_GET_REPORTS_DATA,
        types.SET_GET_REPORTS_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const updateReport = (reportId, data) =>
  buildRSA(
    {
      endpoint: `/reports/${reportId}`,
      method: "PATCH",
      types: [
        types.DO_PATCH_REPORT,
        types.SET_PATCH_REPORT_DATA,
        types.SET_PATCH_REPORT_ERROR
      ],
      body: data
    },
    true,
    false
  );

export const deleteReport = reportId =>
  buildRSA(
    {
      endpoint: `/reports/${reportId}`,
      method: "DELETE",
      types: [
        types.DO_DELETE_REPORT,
        types.SET_DELETE_REPORT_DATA,
        types.SET_DELETE_REPORT_ERROR
      ]
    },
    true,
    false
  );

export default {
  getReports,
  updateReport,
  deleteReport,
  getSystemUserReports,
  clearReports
};
