import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getSubscriptions = (query = {}, previousData = []) => {
  let _query = { skip: 0, limit: 10, ...query };
  _query = queryString.stringify(_query);
  return buildRSA(
    {
      endpoint: `/subscriptions?${_query}`,
      method: "GET",
      types: [
        types.DO_GET_SUBSCRIPTIONS,
        types.SET_GET_SUBSCRIPTIONS_DATA,
        types.SET_GET_SUBSCRIPTIONS_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const postManualSubscription = (userId, days = 30) =>
  buildRSA(
    {
      endpoint: `/subscriptions/manual`,
      method: "POST",
      types: [
        types.DO_POST_MANUAL_SUBSCRIPTION,
        types.SET_POST_MANUAL_SUBSCRIPTION_DATA,
        types.SET_POST_MANUAL_SUBSCRIPTION_ERROR
      ],
      body: { userId, days }
    },
    true,
    false
  );

export const querySubscription = (subscriptionId, data) =>
  buildRSA(
    {
      endpoint: `/subscriptions/query-ccbill`,
      method: "POST",
      types: [
        types.DO_POST_QUERY_SUBSCRIPTION,
        types.SET_POST_QUERY_SUBSCRIPTION_DATA,
        types.SET_POST_QUERY_SUBSCRIPTION_ERROR
      ],
      body: { ...data, subscriptionId }
    },
    true,
    false
  );

export default {
  getSubscriptions,
  postManualSubscription,
  querySubscription
};
