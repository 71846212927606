import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getDatas = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);             
  return buildRSA(
    {
      endpoint: `/vboutPabblyCon/acadleProdTag/?${_query}`,
      method: "GET",
      types: [
        types.GET_ACADLE_PRODUCT_CONNECT,
        types.GET_ACADLE_PRODUCT_CONNECT_SUCCESS,
        types.GET_ACADLE_PRODUCT_CONNECT_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const getAcadleTagList = ()=> {
    return buildRSA(
        {
          endpoint: `/vboutPabblyCon/acadleTags`,
          method: "GET",
          types: [
            types.GET_ACADLE_TAGS,
            types.GET_ACADLE_TAGS_SUCCESS,
            types.GET_ACADLE_TAGS_ERROR
          ]
        },
        true,
        false,
      );
}

export const updateConnect = (id, data) =>
  buildRSA(
    {
      endpoint: `/vboutPabblyCon/acadleProdTag/${id}`,
      method: "PATCH",
      types: [
        types.UPDT_ACADLE_PRODUCT_CONNECT,
        types.UPDT_ACADLE_PRODUCT_CONNECT_SUCCESS,
        types.UPDT_ACADLE_PRODUCT_CONNECT_ERROR
      ],
      body: data
    },
    true,
    false
  );

  
  
export const deleteConnect = id =>
  buildRSA(
    {
      endpoint: `/vboutPabblyCon/acadleProdTag/${id}`,
      method: "DELETE",
      types: [
        types.DELETE_ACADLE_PRODUCT_CONNECT,
        types.DELETE_ACADLE_PRODUCT_CONNECT_SUCCESS,
        types.DELETE_ACADLE_PRODUCT_CONNECT_ERROR
      ]
    },
    true,
    false
  );
 
export const createNewConnect = data =>
  buildRSA(
    {
      endpoint: `/vboutPabblyCon/acadleProdTag`,
      method: "POST",
      types: [
        types.NEW_ACADLE_PRODUCT_CONNECT,
        types.NEW_ACADLE_PRODUCT_CONNECT_SUCCESS,
        types.NEW_ACADLE_PRODUCT_CONNECT_ERROR
      ],
      body: data
    },
    true,
    false
  );

  

export default {
  createNewConnect,
  getAcadleTagList,
  updateConnect,
  deleteConnect, 
  getDatas,
};
