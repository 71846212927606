/* eslint-disable */
import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getSubscriptions = (query = {}, previousData = []) => {
  let _query = { skip: 0, limit: 10, ...query };
  _query = queryString.stringify(_query);
  return buildRSA(
    {
      endpoint: `/email-notifications?${_query}`,
      method: "GET",
      types: [
        types.DO_GET_EMAIL_NOTIFICATIONS,
        types.SET_GET_EMAIL_NOTIFICATIONS_DATA,
        types.SET_GET_EMAIL_NOTIFICATIONS_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const patchEmailNotification = (notificationId, data) => (
  {
    endpoint: `/email-notifications/${notificationId}`,
    method: "PATCH",
    types: [
      types.DO_PATCH_EMAIL_NOTIFICATION,
      types.SET_PATCH_EMAIL_NOTIFICATION_DATA,
      types.SET_PATCH_EMAIL_NOTIFICATION_ERROR
    ],
    body: data
  },
  true,
  false
);

export const adminCustomEmail =(data)=>
  buildRSA({
    endpoint: `/email-notifications/adminEmail`,
    method: "POST",
    body:data,
    types: [
      types.GET_ADMIN_IDS,
      types.GET_ADMIN_IDS_DATA,
      types.GET_ADMIN_IDS_ERROR,
    ],
  },
  true,
  false);




export default {
  getSubscriptions,
  patchEmailNotification,
  adminCustomEmail
};
