import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getDatas = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);             
  return buildRSA(
    {
      endpoint: `/vboutPabblyCon/?${_query}`,
      method: "GET",
      types: [
        types.GET_PABBLY_VBOUT_CONNECT_LIST,
        types.GET_PABBLY_VBOUT_CONNECT_LIST_SUCCESS,
        types.GET_PABBLY_VBOUT_CONNECT_LIST_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const getProdTagsList = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);             
  return buildRSA(
    {
      endpoint: `/vboutPabblyCon/prodTag?${_query}`,
      method: "GET",
      types: [
        types.GET_PABBLY_PRODUCT_TAG_LIST,
        types.GET_PABBLY_PRODUCT_TAG_LIST_SUCCESS,
        types.GET_PABBLY_PRODUCT_TAG_LIST_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const getConnect = (id) => {
  return buildRSA(
    {
      endpoint: `/vboutPabblyCon/${id}`,
      method: "GET",
      types: [
        types.GET_PABBLY_VBOUT_CONNECT,
        types.GET_PABBLY_VBOUT_CONNECT_SUCCESS,
        types.GET_PABBLY_VBOUT_CONNECT_ERROR
      ]
    },
    true,
    false,
  );
};

export const getVboutList = ()=> {
    return buildRSA(
        {
          endpoint: `/vboutPabblyCon/vboutList`,
          method: "GET",
          types: [
            types.GET_VBOUT_LIST,
            types.GET_VBOUT_LIST_SUCCESS,
            types.GET_VBOUT_LIST_ERROR
          ]
        },
        true,
        false,
      );
}

export const getProductList = ()=> {
    return buildRSA(
        {
          endpoint: `/vboutPabblyCon/pabblyProducts`,
          method: "GET",
          types: [
            types.GET_PABBLY_PRODUCTS,
            types.GET_PABBLY_PRODUCTS_SUCCESS,
            types.GET_PABBLY_PRODUCTS_ERROR
          ]
        },
        true,
        false,
      );
}

export const updateConnect = (id, data) =>
  buildRSA(
    {
      endpoint: `/vboutPabblyCon/${id}`,
      method: "PATCH",
      types: [
        types.UPDATE_PABBLY_VBOUT_CONNECT,
        types.UPDATE_PABBLY_VBOUT_CONNECT_SUCCESS,
        types.UPDATE_PABBLY_VBOUT_CONNECT_ERROR
      ],
      body: data
    },
    true,
    false
  );

  
export const updateProdTag = (id, data) =>
buildRSA(
  {
    endpoint: `/vboutPabblyCon/prodTag/${id}`,
    method: "PATCH",
    types: [
      types.UPDATE_PABBLY_PRODUCT_TAG,
      types.UPDATE_PABBLY_PRODUCT_TAG_SUCCESS,
      types.UPDATE_PABBLY_PRODUCT_TAG_ERROR
    ],
    body: data
  },
  true,
  false
);

  
export const deleteConnect = id =>
  buildRSA(
    {
      endpoint: `/vboutPabblyCon/${id}`,
      method: "DELETE",
      types: [
        types.DELETE_PABBLY_VBOUT_CONNECT,
        types.DELETE_PABBLY_VBOUT_CONNECT_SUCCESS,
        types.DELETE_PABBLY_VBOUT_CONNECT_ERROR
      ]
    },
    true,
    false
  );
  
  export const deleteProdTag = id =>
  buildRSA(
    {
      endpoint: `/vboutPabblyCon/${id}`,
      method: "DELETE",
      types: [
        types.DELETE_PABBLY_PRODUCT_TAG,
        types.DELETE_PABBLY_PRODUCT_TAG_SUCCESS,
        types.DELETE_PABBLY_PRODUCT_TAG_ERROR
      ]
    },
    true,
    false
  );
// export const deleteMainInquiry = id =>
// buildRSA(
//   {
//     endpoint: `/adminChat/inquiry/main/${id}`,
//     method: "DELETE",
//     types: [
//       types.DELETE_MAIN_INQUIRY,
//       types.DELETE_MAIN_INQUIRY_SUCCESS,
//       types.DELETE_MAIN_INQUIRY_ERROR
//     ]
//   },
//   true,
//   false
//   // json => {
//   //   let prvData = previousData
//   //   let ary=previousData.data||[]
    
//   //   ary = ary.filter(user =>  user._id.toString() !== json.data._id.toString())
    
//   //   prvData.data=ary
//   //   return prvData;
    
//   // }
// );

export const createNewConnect = data =>
  buildRSA(
    {
      endpoint: `/vboutPabblyCon`,
      method: "POST",
      types: [
        types.NEW_PABBLY_VBOUT_CONNECT,
        types.NEW_PABBLY_VBOUT_CONNECT_SUCCESS,
        types.NEW_PABBLY_VBOUT_CONNECT_ERROR
      ],
      body: data
    },
    true,
    false
  );

  
export const createNewProdTagConnect = data =>
buildRSA(
  {
    endpoint: `/vboutPabblyCon/prodTag`,
    method: "POST",
    types: [
      types.NEW_PABBLY_PRODUCT_TAG,
      types.NEW_PABBLY_PRODUCT_TAG_SUCCESS,
      types.NEW_PABBLY_PRODUCT_TAG_ERROR
    ],
    body: data
  },
  true,
  false
);

export default {
  createNewConnect,
  getConnect,
  updateConnect,
  deleteConnect, 
  getDatas,
  getVboutList,
  getProductList,
  getProdTagsList,
  createNewProdTagConnect,
  updateProdTag,
  deleteProdTag
  
};
