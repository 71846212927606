import types from "../types";
import state from "../defaultState";
import { fromJS } from "immutable";

const initialState = state.chatWindow;
export default function app(state = initialState, action) {
  switch (action.type) {
    case types.SET_CHAT_WINDOW_MESSAGES:
      return state.setIn(["messages"], fromJS(action.payload));
    case types.SET_CHAT_WINDOW_TYPING:
      return state.setIn(["isTyping"], fromJS(action.payload));
    case types.SET_CHAT_WINDOW_OPENED:
      return state.setIn(["isOpened"], fromJS(action.payload));
    case types.SET_CHAT_WINDOW_PROFILE:
      return state.setIn(["profile"], fromJS(action.payload));
    case types.SET_CHAT_WINDOW_STATUS:
      return state.setIn(["status"], fromJS(action.payload));
    case types.SET_CHAT_WINDOW_TOTAL_UNREAD:
      return state.setIn(["totalUnread"], fromJS(action.payload));
    case types.SET_CHAT_WINDOW_CHAT_ID:
      return state.setIn(["chatId"], fromJS(action.payload));
    case types.SET_CHAT_WINDOW_IS_LOADING:
      return state.setIn(["isLoading"], fromJS(action.payload));
    case types.SET_CHAT_WINDOW_TOTAL_API_MESSAGES:
      return state.setIn(["totalAPIMessages"], fromJS(action.payload));
    default:
      return state;
  }
}
