import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getCategories = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);
  return buildRSA(
    {
      endpoint: `/categories?${_query}`,
      method: "GET",
      types: [
        types.DO_GET_CATEGORIES,
        types.SET_GET_CATEGORIES_DATA,
        types.SET_GET_CATEGORIES_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const updateCategory = (categoryId, data) =>
  buildRSA(
    {
      endpoint: `/categories/${categoryId}`,
      method: "PATCH",
      types: [
        types.DO_PATCH_CATEGORY,
        types.SET_PATCH_CATEGORY_DATA,
        types.SET_PATCH_CATEGORY_ERROR
      ],
      body: data
    },
    true,
    false
  );

export const deleteCategory = categoryId =>
  buildRSA(
    {
      endpoint: `/categories/${categoryId}`,
      method: "DELETE",
      types: [
        types.DO_DELETE_CATEGORY,
        types.SET_DELETE_CATEGORY_DATA,
        types.SET_DELETE_CATEGORY_ERROR
      ]
    },
    true,
    false
  );

export const addCategory = data =>
  buildRSA(
    {
      endpoint: `/categories`,
      method: "POST",
      types: [
        types.DO_POST_CATEGORY,
        types.SET_POST_CATEGORY_DATA,
        types.SET_POST_CATEGORY_ERROR
      ],
      body: data
    },
    true,
    false
  );

export default {
  getCategories,
  updateCategory,
  deleteCategory,
  addCategory
};
