import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getMedia = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);
  return buildRSA(
    {
      endpoint: `/media?${_query}`,
      method: "GET",
      types: [
        types.DO_GET_MEDIA_DATA,
        types.SET_GET_MEDIA_DATA,
        types.SET_GET_MEDIA_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const updateMedia = (mediaId, data) =>
  buildRSA(
    {
      endpoint: `/media/${mediaId}`,
      method: "PATCH",
      types: [
        types.DO_PATCH_MEDIA,
        types.SET_PATCH_MEDIA_DATA,
        types.SET_PATCH_MEDIA_ERROR
      ],
      body: data
    },
    true,
    false
  );

export const deleteMedia = mediaId =>
  buildRSA(
    {
      endpoint: `/media/${mediaId}`,
      method: "DELETE",
      types: [
        types.DO_DELETE_MEDIA,
        types.SET_DELETE_MEDIA_DATA,
        types.SET_DELETE_MEDIA_ERROR
      ]
    },
    true,
    false
  );

export default {
  getMedia,
  updateMedia,
  deleteMedia
};
