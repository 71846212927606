import broker from "redux-api-middleware-broker";

import cookie from "react-cookies";
const baseURL =
  process.env.REACT_APP_API_ENV === "development"
  ? "https://api.datingkinky.com"
    // ? "http://192.168.18.47:7300"
    : process.env.REACT_APP_API_ENV === "staging"
    ? "https://staging.datingkinky.com"
    : "https://api.datingkinky.com";
//baseURL is what's being used as the URL for API calls.
// Note: If you want to use the locally hosted API on your machine, all you have to do is change to value of baseUrl. 
//You can do that by commenting line 4/5 - 9 and adding the value of your localhost api for example: const baseURL = "http://localhost:7300";
const baseApIURL = baseURL;
// const baseApIURL = process.env.REACT_APP_BASE_API_URL;

const storeTokenToCookie = (token) => {
  // Expires in the next 24 hours
  const expires = new Date(Date.now() + 24 * 3600 * 1000);
  cookie.save("authToken", token, {
    expires,
    // ...(env !== "development" ? { domain: ".datingkinky.com" } : {})
  });
};

export default (
  options = { endpoint: "/", types: [], method: "GET", body: {} },
  requiresAuth = true,
  isFileUpload = false,
  preprocessResult = (json) => json
) => {
  const authToken = cookie.load("authToken") || "";
  return broker(
    {
      ...options,
      endpoint: `${baseApIURL}${options.endpoint || "/"}`,
      method: options.method || "GET",
      headers: {
        ...(requiresAuth && authToken ? { Authorization: authToken } : {}),
        ...(isFileUpload
          ? {}
          : {
              Accept: "application/json",
              "Content-Type": "application/json",
            }),
        ...(options.headers || {}),
        "Sec-Fetch-Mode": "no-cors",
      },
      mode: "no-cors",
    },
    isFileUpload,
    (action, state, res) => {
      const authToken = res.headers.get("x-auth-token");
      if (authToken) {
        storeTokenToCookie(authToken);
      }
    },
    preprocessResult
  );
};
