import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getUpcoachUsers = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);
  console.log("Get Upcoach Query");
  return buildRSA(
    {
      endpoint: `/reports/upcoachUserReport?${_query}`,
      method: "GET",
      types: [
        types.GET_UPCOACH_USERS_TO_DELETE,
        types.GET_UPCOACH_USERS_TO_DELETE_SUCCESS,
        types.GET_UPCOACH_USERS_TO_DELETE_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const deleteReport = reportId=>
  buildRSA(
    {
      endpoint: `/reports/upcoachUserReport/${reportId}`,
      method: "DELETE",
      types: [
        types.DELETE_UPCOACH_USERS_TO_DELETE,
        types.DELETE_UPCOACH_USERS_TO_DELETE_SUCCESS,
        types.DELETE_UPCOACH_USERS_TO_DELETE_ERROR
      ],
    },
    true,
    false
  );




export default {
  getUpcoachUsers,
  deleteReport,
};
