import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getTags = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);
  return buildRSA(
    {
      endpoint: `/kinks-and-interests?${_query}`,
      method: "GET",
      types: [
        types.DO_GET_TAGS,
        types.SET_GET_TAGS_DATA,
        types.SET_GET_TAGS_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const updateTag = (tagId, data) =>
  buildRSA(
    {
      endpoint: `/kinks-and-interests/${tagId}`,
      method: "PATCH",
      types: [
        types.DO_PATCH_TAG,
        types.SET_PATCH_TAG_DATA,
        types.SET_PATCH_TAG_ERROR
      ],
      body: data
    },
    true,
    false
  );

export const deleteTag = tagId =>
  buildRSA(
    {
      endpoint: `/kinks-and-interests/${tagId}`,
      method: "DELETE",
      types: [
        types.DO_DELETE_TAG,
        types.SET_DELETE_TAG_DATA,
        types.SET_DELETE_TAG_ERROR
      ]
    },
    true,
    false
  );

export const createTag = data =>
  buildRSA(
    {
      endpoint: `/kinks-and-interests`,
      method: "POST",
      types: [
        types.DO_ADD_NEW_TAG,
        types.SET_ADD_NEW_TAG_DATA,
        types.SET_ADD_NEW_TAG_ERROR
      ],
      body: data
    },
    true,
    false
  );

export default {
  getTags,
  updateTag,
  deleteTag,
  createTag
};
