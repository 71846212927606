import types from "../types";
import state from "../defaultState";
import { fromJS } from "immutable";

const initialState = state.reg;
export default function app(state = initialState, action) {
  switch (action.type) {
    case types.SET_REGISTER_DATA:
      return state.setIn(["userData"], fromJS(action.payload));
    case types.SET_VERIFY_USER_REG_DATA:
      return state.setIn(["verifyRegData"], fromJS(action.payload));
    default:
      return state;
  }
}
