import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getWritings = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);
  return buildRSA(
    {
      endpoint: `/writings?${_query}`,
      method: "GET",
      types: [
        types.DO_GET_WRITINGS_DATA,
        types.SET_GET_WRITINGS_DATA,
        types.SET_GET_WRITINGS_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const updateWriting = (writingId, data) =>
  buildRSA(
    {
      endpoint: `/writings/${writingId}`,
      method: "PATCH",
      types: [
        types.DO_PATCH_WRITING,
        types.SET_PATCH_WRITING_DATA,
        types.SET_PATCH_WRITING_ERROR
      ],
      body: data
    },
    true,
    false
  );

export const deleteWriting = writingId =>
  buildRSA(
    {
      endpoint: `/writings/${writingId}`,
      method: "DELETE",
      types: [
        types.DO_DELETE_WRITING,
        types.SET_DELETE_WRITING_DATA,
        types.SET_DELETE_WRITING_ERROR
      ]
    },
    true,
    false
  );

export default {
  getWritings,
  updateWriting,
  deleteWriting
};
