import types from "../types";
import state from "../defaultState";
import { fromJS } from "immutable";

const initialState = state.vbPabProdTag;
export default function app(state = initialState, action) {
  switch (action.type) {
    case types.GET_VBOUT_LIST_SUCCESS:
      return state.setIn(["vboutList"], fromJS(action.payload));
    case types.GET_PABBLY_PRODUCTS_SUCCESS:
      return state.setIn(["products"], fromJS(action.payload));
    case types.GET_PABBLY_PRODUCT_TAG_LIST_SUCCESS:
      return state.setIn(["data"], fromJS(action.payload));
    // case types.GET_BANNED_MESSAGES_ERROR:
    //   return state.setIn(
    //     ["error"],
    //     fromJS(
    //       action.payload.response
    //         ? action.payload.response.error
    //         : action.payload.message
    //     )
    //   );
    default:
      return state;
  }
}
