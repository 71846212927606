import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { appActions } from "./store/actions";

import { Provider } from "react-redux";
import createStore from "./store";
// import { renderRoutes } from 'react-router-config';
import Loadable from "react-loadable";
import "./App.scss";

import cookie from "react-cookies";
import jwtDecode from "jwt-decode";
console.log(process.env.REACT_APP_API_ENV);
const store = createStore(null, {});

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

const Login = Loadable({
  loader: () => import("./views/Pages/Login"),
  loading,
});

// Containers
const DefaultLayout = Loadable({
  loader: () => import("./containers/DefaultLayout"),
  loading,
});

class App extends Component {
  clearAllCookies = () => {
    var res = document.cookie;
    var multiple = res.split(";");
    for (var i = 0; i < multiple.length; i++) {
      var key = multiple[i].split("=");
      document.cookie = key[0] + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
    }
  };

  isAuthorizedRole = (roleName) => {
    return [
      "Users Moderator",
      "Posts Moderator",
      "Admin",
      "Media Moderator",
      "Super Admin",
    ].includes(roleName);
  };
  componentWillMount() {
    const goToLogin = () => {
      const pathname = window.location.pathname;
      if (pathname !== "/login") {
        window.location.replace(`/login?redirect=${pathname}`);
      }
    };
    const authToken = cookie.load("authToken");
    if (authToken) {
      const payload = jwtDecode(authToken);
      const now = Date.now();
      const isAuthenticated =
        payload.exp * 1000 > now && this.isAuthorizedRole(payload.role.name);
      if (!isAuthenticated) {
        this.clearAllCookies();
        goToLogin();
      }
      store.dispatch(appActions.loadOptionList());
    } else {
      goToLogin();
    }
  }
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
