import types from "../types";
import buildRSA from "./buildRSA";

export const updateAccount = data => {
  return buildRSA({
    endpoint: "/me/account",
    method: "PATCH",
    types: [
      types.DO_UPDATE_CURRENT_USER_ACCOUNT,
      types.SET_UPDATE_CURRENT_USER_ACCOUNT_DATA,
      types.SET_UPDATE_CURRENT_USER_ACCOUNT_ERROR
    ],
    body: data
  });
};

export const sendEmailUpdateToken = data => {
  return buildRSA({
    endpoint: "/me/email-update",
    method: "POST",
    types: [
      types.DO_SEND_CHANGE_EMAIL_TOKEN,
      types.SET_SEND_CHANGE_EMAIL_TOKEN_DATA,
      types.SET_SEND_CHANGE_EMAIL_TOKEN_ERROR
    ],
    body: data
  });
};

export const confirmEmailUpdateToken = data => {
  return buildRSA({
    endpoint: "/me/email-update",
    method: "PATCH",
    types: [
      types.DO_CONFIRM_CHANGE_EMAIL_TOKEN,
      types.SET_CONFIRM_CHANGE_EMAIL_TOKEN_DATA,
      types.SET_CONFIRM_CHANGE_EMAIL_TOKEN_ERROR
    ],
    body: data
  });
};

export const updateProfile = data => {
  return buildRSA({
    endpoint: "/me/profile",
    method: "PATCH",
    types: [
      types.DO_UPDATE_CURRENT_USER_PROFILE,
      types.SET_UPDATE_CURRENT_USER_PROFILE_DATA,
      types.SET_UPDATE_CURRENT_USER_PROFILE_ERROR
    ],
    body: data
  });
};

export const updatePreferences = data => {
  return buildRSA({
    endpoint: "/me/preferences",
    method: "PATCH",
    types: [
      types.DO_UPDATE_CURRENT_USER_PREFERENCE,
      types.SET_UPDATE_CURRENT_USER_PREFERENCE_DATA,
      types.SET_UPDATE_CURRENT_USER_PREFERENCE_ERROR
    ],
    body: data
  });
};

export const getProfile = () =>
  buildRSA({
    endpoint: "/me",
    method: "GET",
    types: [
      types.DO_CURRENT_USER_DATA,
      types.SET_CURRENT_USER_DATA,
      types.SET_CURRENT_USER_ERROR
    ]
  });

export const addTagToProfile = data =>
  buildRSA({
    endpoint: "/me/kinks-and-interests",
    method: "PATCH",
    types: [
      types.DO_ADD_TAG_TO_PROFILE,
      types.SET_ADD_TAG_TO_PROFILE_DATA,
      types.SET_ADD_TAG_TO_PROFILE_DATA
    ],
    body: data
  });

export const blockUser = data =>
  buildRSA({
    endpoint: `/me/block-list`,
    method: "POST",
    types: [
      types.DO_BLOCK_USER,
      types.SET_BLOCK_USER_DATA,
      types.SET_BLOCK_USER_ERROR
    ],
    body: data
  });

export const unBlockUser = data =>
  buildRSA({
    endpoint: `/me/block-list`,
    method: "DELETE",
    types: [
      types.DO_UNBLOCK_USER,
      types.SET_UNBLOCK_USER_DATA,
      types.SET_UNBLOCK_USER_ERROR
    ],
    body: data
  });

export const sendFriendRequest = data =>
  buildRSA({
    endpoint: `/me/friends`,
    method: "POST",
    types: [
      types.DO_SEND_FRIEND_REQUEST,
      types.SET_SEND_FRIEND_REQUEST_DATA,
      types.SET_SEND_FRIEND_REQUEST_ERROR
    ],
    body: data
  });

export const updateFriendRequest = (id, data) =>
  buildRSA({
    endpoint: `/me/friends/${id}`,
    method: "PATCH",
    types: [
      types.DO_UPDATE_FRIEND_REQUEST,
      types.SET_UPDATE_FRIEND_REQUEST_DATA,
      types.SET_UPDATE_FRIEND_REQUEST_ERROR
    ],
    body: data
  });

export const sendFlirtRequest = data =>
  buildRSA({
    endpoint: `/me/flirts`,
    method: "POST",
    types: [
      types.DO_SEND_FLIRT_REQUEST,
      types.SET_SEND_FLIRT_REQUEST_DATA,
      types.SET_SEND_FLIRT_REQUEST_ERROR
    ],
    body: data
  });

export const updateFlirtRequest = (id, data) =>
  buildRSA({
    endpoint: `/me/flirts/${id}`,
    method: "PATCH",
    types: [
      types.DO_UPDATE_FLIRT_REQUEST,
      types.SET_UPDATE_FLIRT_REQUEST_DATA,
      types.SET_UPDATE_FLIRT_REQUEST_ERROR
    ],
    body: data
  });

export const sendRelationshipRequest = data =>
  buildRSA({
    endpoint: `/me/relationships`,
    method: "POST",
    types: [
      types.DO_SEND_RELATIONSHIP_REQUEST,
      types.SET_SEND_RELATIONSHIP_REQUEST_DATA,
      types.SET_SEND_RELATIONSHIP_REQUEST_ERROR
    ],
    body: data
  });

export const updateRelationshipRequest = (id, data) =>
  buildRSA({
    endpoint: `/me/relationships/${id}`,
    method: "PATCH",
    types: [
      types.DO_UPDATE_RELATIONSHIP_REQUEST,
      types.SET_UPDATE_RELATIONSHIP_REQUEST_DATA,
      types.SET_UPDATE_RELATIONSHIP_REQUEST_ERROR
    ],
    body: data
  });

export const getProfileViewers = (skip = 0, limit = 10, previousData = []) =>
  buildRSA(
    {
      endpoint: `/me/profile-viewers?skip=${skip}&limit=${limit}`,
      method: "GET",
      types: [
        types.DO_GET_USER_PROFILE_VIEWERS,
        types.SET_USER_PROFILE_VIEWERS_DATA,
        types.SET_USER_PROFILE_VIEWERS_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );

export const setTotalUnreadConversation = count => {
  return {
    payload: count,
    type: types.SET_TOTAL_UNREAD_CONVERSATION
  };
};

export const setConversationList = data => {
  return {
    payload: data,
    type: types.SET_USER_CONVERSATION_LIST
  };
};

export default {
  getProfile,
  updateProfile,
  updateAccount,
  updatePreferences,
  sendEmailUpdateToken,
  confirmEmailUpdateToken,
  addTagToProfile,
  blockUser,
  unBlockUser,
  sendFriendRequest,
  sendFlirtRequest,
  updateFriendRequest,
  updateFlirtRequest,
  sendRelationshipRequest,
  updateRelationshipRequest,
  getProfileViewers,
  setTotalUnreadConversation,
  setConversationList
};
