import types from "../types";
import buildRSA from "./buildRSA";
// import queryString from "query-string";

export const getNavBooks = (query = {}, previousData = []) => {
  // const _query = queryString.stringify(query);
  return buildRSA(
    {
      endpoint: `/navTopics/getBooks`,
      method: "GET",
      types: [
        types.GET_NAV_BOOKS,
        types.GET_NAV_BOOKS_SUCCESS,
        types.GET_NAV_BOOKS_ERROR
      ]
    },
    true,
    false,
    // json => {
    //   json.data = previousData.concat(json.data);
    //   return json;
    // }
  );
};

export const updateBook = (eventId, data) =>
  buildRSA(
    {
      endpoint: `/navTopics/patchBook/${eventId}`,
      method: "PATCH",
      types: [
        types.PATCH_NAV_BOOKS,
        types.PATCH_NAV_BOOKS_SUCCESS,
        types.PATCH_NAV_BOOKS_ERROR
      ],
      body: data
    },
    true,
    false
  );

export const deleteBook = eventId =>
  buildRSA(
    {
      endpoint: `/navTopics/removeBook/${eventId}`,
      method: "DELETE",
      types: [
        types.DELETE_NAV_BOOKS,
        types.DELETE_NAV_BOOKS_SUCCESS,
        types.DELETE_NAV_BOOKS_ERROR
      ]
    },
    true,
    false
  );

export const createNewBook = data =>
  buildRSA(
    {
      endpoint: `/navTopics/newBook`,
      method: "POST",
      types: [
        types.ADD_NAV_BOOKS,
        types.ADD_NAV_BOOKS_SUCCESS,
        types.ADD_NAV_BOOKS_ERROR
      ],
      body: data
    },
    true,
    false
  );


export default {
  createNewBook,
  getNavBooks,
  deleteBook,
  updateBook
};
