import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getInquiry = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);             
  return buildRSA(
    {
      endpoint: `/adminChat/inquiry?${_query}`,
      method: "GET",
      types: [
        types.GET_INQUIRY,
        types.GET_INQUIRY_SUCCESS,
        types.GET_INQUIRY_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};


export const getMainTopic = () => {
  return buildRSA(
    {
      endpoint: `/adminChat/inquiry?type=main`,
      method: "GET",
      types: [
        types.GET_MAIN_TOPIC,
        types.GET_MAIN_TOPIC_SUCCESS,
        types.GET_MAIN_TOPIC_ERROR
      ]
    },
    true,
    false,
  );
};

export const updateInq = (id, data) =>
  buildRSA(
    {
      endpoint: `/adminChat/inquiry/sub/${id}`,
      method: "PATCH",
      types: [
        types.UPDATE_INQUIRY,
        types.UPDATE_INQUIRY_SUCCESS,
        types.UPDATE_INQUIRY_ERROR
      ],
      body: data
    },
    true,
    false
  );

  export const updateMainInq = (id, data) =>
  buildRSA(
    {
      endpoint: `/adminChat/inquiry/main/${id}`,
      method: "PATCH",
      types: [
        types.UPDATE_MAIN_INQUIRY,
        types.UPDATE_MAIN_INQUIRY_SUCCESS,
        types.UPDATE_MAIN_INQUIRY_ERROR
      ],
      body: data
    },
    true,
    false
  );

export const deleteSubInquiry = id =>
  buildRSA(
    {
      endpoint: `/adminChat/inquiry/sub/${id}`,
      method: "DELETE",
      types: [
        types.DELETE_INQUIRY,
        types.DELETE_INQUIRY_SUCCESS,
        types.DELETE_INQUIRY_ERROR
      ]
    },
    true,
    false
  );
  
export const deleteMainInquiry = id =>
buildRSA(
  {
    endpoint: `/adminChat/inquiry/main/${id}`,
    method: "DELETE",
    types: [
      types.DELETE_MAIN_INQUIRY,
      types.DELETE_MAIN_INQUIRY_SUCCESS,
      types.DELETE_MAIN_INQUIRY_ERROR
    ]
  },
  true,
  false
  // json => {
  //   let prvData = previousData
  //   let ary=previousData.data||[]
    
  //   ary = ary.filter(user =>  user._id.toString() !== json.data._id.toString())
    
  //   prvData.data=ary
  //   return prvData;
    
  // }
);

export const createNewInquiry = data =>
  buildRSA(
    {
      endpoint: `/adminChat/inquiry`,
      method: "POST",
      types: [
        types.ADD_NEW_INQUIRY,
        types.ADD_NEW_INQUIRY_SUCCESS,
        types.ADD_NEW_INQUIRY_ERROR
      ],
      body: data
    },
    true,
    false
  );

export default {
  getInquiry,
  getMainTopic,
  updateInq,
  updateMainInq,
  createNewInquiry,
  deleteMainInquiry,
  deleteSubInquiry
  
};
