import types from "../types";
// import buildRSA from "./buildRSA";

export const chatUserDetails=(data)=> async(dispatch)=>{
    return dispatch({type:types.SET_CHAT_DETAILS, payload: data})
   }

   
export default {
    chatUserDetails
};