import types from "../types";
import buildRSA from "./buildRSA";
// import queryString from "query-string";

export const getNavCommunitys = (query = {}, previousData = []) => {
  // const _query = queryString.stringify(query);
  return buildRSA(
    {
      endpoint: `/navTopics/getCommunity`,
      method: "GET",
      types: [
        types.GET_NAV_COMMUNITY,
        types.GET_NAV_COMMUNITY_SUCCESS,
        types.GET_NAV_COMMUNITY_ERROR
      ]
    },
    true,
    false,
    // json => {
    //   json.data = previousData.concat(json.data);
    //   return json;
    // }
  );
};

export const updateCommunity = (eventId, data) =>
  buildRSA(
    {
      endpoint: `/navTopics/patchCommunity/${eventId}`,
      method: "PATCH",
      types: [
        types.PATCH_NAV_COMMUNITY,
        types.PATCH_NAV_COMMUNITY_SUCCESS,
        types.PATCH_NAV_COMMUNITY_ERROR
      ],
      body: data
    },
    true,
    false
  );

export const deleteCommunity = eventId =>
  buildRSA(
    {
      endpoint: `/navTopics/removeCommunity/${eventId}`,
      method: "DELETE",
      types: [
        types.DELETE_NAV_COMMUNITY,
        types.DELETE_NAV_COMMUNITY_SUCCESS,
        types.DELETE_NAV_COMMUNITY_ERROR
      ]
    },
    true,
    false
  );

export const createNewCommunity = data =>
  buildRSA(
    {
      endpoint: `/navTopics/newCommunity`,
      method: "POST",
      types: [
        types.ADD_NAV_COMMUNITY,
        types.ADD_NAV_COMMUNITY_SUCCESS,
        types.ADD_NAV_COMMUNITY_ERROR
      ],
      body: data
    },
    true,
    false
  );


export default {
  createNewCommunity,
  getNavCommunitys,
  deleteCommunity,
  updateCommunity
};
