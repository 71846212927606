import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getBannedMessages = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);
  return buildRSA(
    {
      endpoint: `/bannedUsern?${_query}`,
      method: "GET",
      types: [
        types.GET_BANNED_USERNAMES,
        types.GET_BANNED_USERNAMES_SUCCESS,
        types.GET_BANNED_USERNAMES_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const updateMessage = (tagId, data) =>
  buildRSA(
    {
      endpoint: `/bannedUsern/${tagId}`,
      method: "PATCH",
      types: [
        types.UPDATE_BANNED_USERNAMES,
        types.UPDATE_BANNED_USERNAMES_SUCCESS,
        types.UPDATE_BANNED_USERNAMES_ERROR
      ],
      body: data
    },
    true,
    false
  );

export const deleteMessage = tagId =>
  buildRSA(
    {
      endpoint: `/bannedUsern/${tagId}`,
      method: "DELETE",
      types: [
        types.DELETE_BANNED_USERNAMES,
        types.DELETE_BANNED_USERNAMES_SUCCESS,
        types.DELETE_BANNED_USERNAMES_ERROR
      ]
    },
    true,
    false
  );

export const createNew = data =>
  buildRSA(
    {
      endpoint: `/bannedUsern`,
      method: "POST",
      types: [
        types.CREATE_BANNED_USERNAMES,
        types.CREATE_BANNED_USERNAMES_SUCCESS,
        types.CREATE_BANNED_USERNAMES_ERROR
      ],
      body: data
    },
    true,
    false
  );

  export default {
  createNew,
  updateMessage,
  deleteMessage,
  getBannedMessages,

};

