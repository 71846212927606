import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getConversations = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);
  return buildRSA(
    {
      endpoint: `/conversations?${_query}`,
      method: "GET",
      types: [
        types.DO_GET_CONVERSATIONS,
        types.SET_GET_CONVERSATIONS_DATA,
        types.SET_GET_CONVERSATIONS_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const updateConversation = (conversationId, data) =>
  buildRSA(
    {
      endpoint: `/conversations/${conversationId}`,
      method: "PATCH",
      types: [
        types.DO_PATCH_CONVERSATION,
        types.SET_PATCH_CONVERSATION_DATA,
        types.SET_PATCH_CONVERSATION_ERROR
      ],
      body: data
    },
    true,
    false
  );

export const deleteConversation = conversationId =>
  buildRSA(
    {
      endpoint: `/conversations/${conversationId}`,
      method: "DELETE",
      types: [
        types.DO_DELETE_CONVERSATION,
        types.SET_DELETE_CONVERSATION_DATA,
        types.SET_DELETE_CONVERSATION_ERROR
      ]
    },
    true,
    false
  );

export default {
  getConversations,
  updateConversation,
  deleteConversation
};
