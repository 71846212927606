import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getPhotos = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);
  return buildRSA(
    {
      endpoint: `/photos?${_query}`,
      method: "GET",
      types: [
        types.DO_GET_PHOTOS_DATA,
        types.SET_PHOTOS_DATA,
        types.SET_PHOTOS_ERROR,
      ],
    },
    true,
    false,
    (json) => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const getPhotoVerifs = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);
  return buildRSA(
    {
      endpoint: `/photos/photoVerif/get?${_query}`,
      method: "GET",
      types: [
        types.DO_GET_PHOTOS_DATA,
        types.SET_PHOTOS_DATA,
        types.SET_PHOTOS_ERROR,
      ],
    },
    true,
    false,
    (json) => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const getVideoVerifs = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);
  return buildRSA(
    {
      endpoint: `/photos/videoVerif/get?${_query}`,
      method: "GET",
      types: [
        types.DO_GET_PHOTOS_DATA,
        types.SET_PHOTOS_DATA,
        types.SET_PHOTOS_ERROR,
      ],
    },
    true,
    false,
    (json) => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const updatePhotoVerif = (photoId, data) =>
  buildRSA(
    {
      endpoint: `/photos/photoVerif/${photoId}`,
      method: "PATCH",
      types: [
        types.DO_PATCH_PHOTO,
        types.SET_PATCH_PHOTO_DATA,
        types.SET_PATCH_PHOTO_ERROR,
      ],
      body: data,
    },
    true,
    false
  );

  export const updateVideoVerif = (photoId, data) =>
  buildRSA(
    {
      endpoint: `/photos/videoVerif/${photoId}`,
      method: "PATCH",
      types: [
        types.DO_PATCH_PHOTO,
        types.SET_PATCH_PHOTO_DATA,
        types.SET_PATCH_PHOTO_ERROR,
      ],
      body: data,
    },
    true,
    false
  );
export const updatePhoto = (photoId, data) =>
  buildRSA(
    {
      endpoint: `/photos/${photoId}`,
      method: "PATCH",
      types: [
        types.DO_PATCH_PHOTO,
        types.SET_PATCH_PHOTO_DATA,
        types.SET_PATCH_PHOTO_ERROR,
      ],
      body: data,
    },
    true,
    false
  );

export const deletePhoto = (photoId) =>
  buildRSA(
    {
      endpoint: `/photos/${photoId}`,
      method: "DELETE",
      types: [
        types.DO_DELETE_PHOTO,
        types.SET_DELETE_PHOTO_DATA,
        types.SET_DELETE_PHOTO_ERROR,
      ],
    },
    true,
    false
  );

  export const postPhoto = (data) => {
    return buildRSA({
      endpoint: "/photos",
      method: "POST",
      types: ["DO_POST_PHOTO", "SET_POST_PHOTO_DATA", "SET_POST_PHOTO_ERROR"],
      body: data,
    });
  };

  export const photoUrl = (data) =>{
    return buildRSA(
      {
        endpoint: `/photos/photoUrl/${data}`,
        method: "GET",
        types: [          
          "GET_PHOTO_URL",
          "GET_PHOTO_URL_SUCCESS",
          "GET_PHOTO_URL_ERROR",
        ],
      },
      false
    );
  }
export default {
  getPhotos,
  getPhotoVerifs,
  updatePhoto,
  deletePhoto,
  updatePhotoVerif,
  getVideoVerifs,
  updateVideoVerif,
  photoUrl,
  postPhoto

};
