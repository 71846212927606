import types from "../../typesBoundaries.json";
import state from "../../defaultState";
import { fromJS } from "immutable";

const initialState = state.boundariesUsers;
export default function app(state = initialState, action) {
  switch (action.type) {
    case types.GET_BOUNDARIES_USERS_SUCCESS:
      return state.setIn(["data"], fromJS(action.payload));
    // case types.GET_BOUNDARIES_USERS_ERROR:
    //   return state.setIn(["error"], fromJS(action.payload.response.error));
    default:
      return state;
  }
}
