import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getBooks = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);
  return buildRSA(
    {
      endpoint: `/plusPage/plusBooks?${_query}`,
      method: "GET",
      types: [
        types.GET_PLUS_BOOKS,
        types.GET_PLUS_BOOKS_SUCCESS,
        types.GET_PLUS_BOOKS_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const updateBook = (tagId, data) =>
  buildRSA(
    {
      endpoint: `/plusPage/updateBook/${tagId}`,
      method: "PATCH",
      types: [
        types.UPDATE_PLUS_BOOK,
        types.UPDATE_PLUS_BOOK_SUCCESS,
        types.UPDATE_PLUS_BOOK_ERROR
      ],
      body: data
    },
    true,
    false
  );

export const deleteBook = tagId =>
  buildRSA(
    {
      endpoint: `/plusPage/removeBook/${tagId}`,
      method: "DELETE",
      types: [
        types.DELETE_PLUS_BOOK,
        types.DELETE_PLUS_BOOK_SUCCESS,
        types.DELETE_PLUS_BOOK_ERROR
      ]
    },
    true,
    false
  );

export const createBook = data =>
  buildRSA(
    {
      endpoint: `/plusPage/plusBooks`,
      method: "POST",
      types: [
        types.CREATE_PLUS_BOOK,
        types.CREATE_PLUS_BOOK_SUCCESS,
        types.CREATE_PLUS_BOOK_ERROR
      ],
      body: data
    },
    true,
    false
  );

export default {
  getBooks,
  updateBook,
  deleteBook,
  createBook
};
