import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getComments = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);
  return buildRSA(
    {
      endpoint: `/comments?${_query}`,
      method: "GET",
      types: [
        types.DO_GET_COMMENTS,
        types.SET_GET_COMMENTS_DATA,
        types.SET_GET_COMMENTS_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const updateComment = (roleId, data) =>
  buildRSA(
    {
      endpoint: `/comments/${roleId}`,
      method: "PATCH",
      types: [
        types.DO_PATCH_COMMENT,
        types.SET_PATCH_COMMENT_DATA,
        types.SET_PATCH_COMMENT_ERROR
      ],
      body: data
    },
    true,
    false
  );

export const deleteComment = roleId =>
  buildRSA(
    {
      endpoint: `/comments/${roleId}`,
      method: "DELETE",
      types: [
        types.DO_DELETE_COMMENT,
        types.SET_DELETE_COMMENT_DATA,
        types.SET_DELETE_COMMENT_ERROR
      ]
    },
    true,
    false
  );

export default {
  getComments,
  updateComment,
  deleteComment
};
