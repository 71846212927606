import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getUsers = (query = {}, previousData = []) => {
  let _query = { skip: 0, limit: 10, ...query };
  _query = queryString.stringify(_query);
  return buildRSA(
    {
      endpoint: `/users?${_query}`,
      method: "GET",
      types: [
        types.DO_GET_USERS_DATA,
        types.SET_USERS_DATA,
        types.SET_USERS_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const updateUser = (userId, data) =>
  buildRSA(
    {
      endpoint: `/users/${userId}`,
      method: "PATCH",
      types: [
        types.DO_PATCH_USER,
        types.SET_PATCH_USER_DATA,
        types.SET_PATCH_USER_ERROR
      ],
      body: data
    },
    true,
    false
  );

export const deleteUser = userId =>
  buildRSA(
    {
      endpoint: `/users/${userId}`,
      method: "DELETE",
      types: [
        types.DO_DELETE_USER,
        types.SET_DELETE_USER_DATA,
        types.SET_DELETE_USER_ERROR
      ]
    },
    true,
    false
  );

export const getUser = userId =>
  buildRSA(
    {
      endpoint: `/users/${userId}`,
      method: "GET",
      types: [
        types.DO_GET_USER,
        types.SET_GET_USER_DATA,
        types.SET_GET_USER_ERROR
      ]
    },
    true,
    false
  );

  export const getNewUsers = (query = {}, previousData = []) =>{
  let _query = { skip: 0, limit: 10, ...query };
  _query = queryString.stringify(_query);
  return buildRSA(
    {
      endpoint: `/users/newUsers?${_query}`,
      method: "GET",
      types: [
        types.DO_GET_USERS_DATA,
        types.SET_USERS_DATA,
        types.SET_USERS_ERROR
      ],
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  )
};

export const resetUserPassword = (
  data = { email: "", token: "", password: "" }
) =>
  buildRSA(
    {
      endpoint: `/reset-password`,
      method: "PATCH",
      types: [
        types.DO_PATCH_RESET_USER_PASSWORD,
        types.SET_PATCH_RESET_USER_PASSWORD_SUCCESS,
        types.SET_PATCH_RESET_USER_PASSWORD_ERROR
      ],
      body: data
    },
    true,
    false
  );

  export const impersonateUser = (data) =>
  buildRSA({
    endpoint: `/authenticate/impersonate`,
    method: "POST",
    body: data,
    types: [
      types.DO_IMPERSONATE_USER,
      types.SET_IMPERSONATE_USER_DATA,
      types.SET_IMPERSONATE_USER_ERROR,
    ],
  });

  export const adminUsers = () =>
  buildRSA({
    endpoint: `/users/adminUser`,
    method: "GET",
    types: [
      types.GET_ADMIN_IDS,
      types.GET_ADMIN_IDS_DATA,
      types.GET_ADMIN_IDS_ERROR,
    ],
  });

  export const giveRetrial = (userId) =>
  buildRSA({
    endpoint: `/subscriptions/admin/giveReTrial/${userId}`,
    method: "POST",
    types: [
      types.GIVE_RETRIAL_SUBSCRPTION,
      types.GIVE_RETRIAL_SUBSCRPTION_SUCCESS,
      types.GIVE_RETRIAL_SUBSCRPTION_ERROR,
    ],
  })

  export const addEmailList = (userId, data) =>
  buildRSA({
    endpoint: `/users/connectEmail/${userId}`,
    method: "POST",
    body: data,
    types: [
      types.CREATE_USER_EMAIL_LIST,
      types.CREATE_USER_EMAIL_LIST_SUCCESS,
      types.CREATE_USER_EMAIL_LIST_ERROR,
    ],
  })

  export const patchEmailList = (userId, data) =>
  buildRSA({
    endpoint: `/users/connectEmail/${userId}`,
    method: "PATCH",
    body: data,
    types: [
      types.PATCH_USER_EMAIL_LIST,
      types.PATCH_USER_EMAIL_LIST_SUCCESS,
      types.PATCH_USER_EMAIL_LIST_ERROR,
    ],
  })



export default {
  getUsers,
  getUser,
  updateUser,
  deleteUser,
  resetUserPassword,
  getNewUsers,
  impersonateUser,
  adminUsers,
  giveRetrial,
  addEmailList,
  patchEmailList
};
