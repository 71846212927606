import types from "../types";
import buildRSA from "./buildRSA";
import queryString from "query-string";

export const getRoles = (query = {}, previousData = []) => {
  const _query = queryString.stringify(query);
  return buildRSA(
    {
      endpoint: `/roles?${_query}`,
      method: "GET",
      types: [
        types.DO_GET_ROLES,
        types.SET_GET_ROLES_DATA,
        types.SET_GET_ROLES_ERROR
      ]
    },
    true,
    false,
    json => {
      json.data = previousData.concat(json.data);
      return json;
    }
  );
};

export const updateRole = (roleId, data) =>
  buildRSA(
    {
      endpoint: `/roles/${roleId}`,
      method: "PATCH",
      types: [
        types.DO_PATCH_ROLE,
        types.SET_PATCH_ROLE_DATA,
        types.SET_PATCH_ROLE_ERROR
      ],
      body: data
    },
    true,
    false
  );

export const deleteRole = roleId =>
  buildRSA(
    {
      endpoint: `/roles/${roleId}`,
      method: "DELETE",
      types: [
        types.DO_DELETE_ROLE,
        types.SET_DELETE_ROLE_DATA,
        types.SET_DELETE_ROLE_ERROR
      ]
    },
    true,
    false
  );

export const addRole = data =>
  buildRSA(
    {
      endpoint: `/roles`,
      method: "POST",
      types: [
        types.DO_POST_ROLE,
        types.SET_POST_ROLE_DATA,
        types.SET_POST_ROLE_ERROR
      ],
      body: data
    },
    true,
    false
  );

export default {
  getRoles,
  updateRole,
  deleteRole,
  addRole
};
