import types from "../types";
import buildRSA from "./buildRSA";
// import queryString from "query-string";

export const getNavEvent = (query = {}, previousData = []) => {
  // const _query = queryString.stringify(query);
  return buildRSA(
    {
      endpoint: `/navTopics/getEvents`,
      method: "GET",
      types: [
        types.GET_NAV_EVENTS,
        types.GET_NAV_EVENTS_SUCCESS,
        types.GET_NAV_EVENTS_ERROR
      ]
    },
    true,
    false,
    // json => {
    //   json.data = previousData.concat(json.data);
    //   return json;
    // }
  );
};

export const updateEvent = (eventId, data) =>
  buildRSA(
    {
      endpoint: `/navTopics/patchEvent/${eventId}`,
      method: "PATCH",
      types: [
        types.PATCH_NAV_EVENTS,
        types.PATCH_NAV_EVENTS_SUCCESS,
        types.PATCH_NAV_EVENTS_ERROR
      ],
      body: data
    },
    true,
    false
  );

export const deleteEvent = eventId =>
  buildRSA(
    {
      endpoint: `/navTopics/removeEvent/${eventId}`,
      method: "DELETE",
      types: [
        types.DELETE_NAV_EVENTS,
        types.DELETE_NAV_EVENTS_SUCCESS,
        types.DELETE_NAV_EVENTS_ERROR
      ]
    },
    true,
    false
  );

export const createNewEvent = data =>
  buildRSA(
    {
      endpoint: `/navTopics/newEvent`,
      method: "POST",
      types: [
        types.ADD_NAV_EVENTS,
        types.ADD_NAV_EVENTS_SUCCESS,
        types.ADD_NAV_EVENTS_ERROR
      ],
      body: data
    },
    true,
    false
  );


export default {
  createNewEvent,
  getNavEvent,
  deleteEvent,
  updateEvent
};
