import types from "../types";
import state from "../defaultState";
import { fromJS } from "immutable";

const initialState = state.chat;
export default function app(state = initialState, action) {
  switch (action.type) {
    case types.SET_CHAT_DETAILS:
      return state.setIn(["data"], fromJS(action.payload));
    // case types.SET_GET_CATEGORIES_ERROR:
    //   return state.setIn(["error"], fromJS(action.payload));
    default:
      return state;
  }
}
