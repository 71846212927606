import types from "../types";
import { fromJS } from "immutable";
import { RSAA } from "redux-api-middleware";
import buildRSA from "./buildRSA";
const baseApIURL = process.env.REACT_APP_BASE_API_URL;
// import optionService from "./../../services/options";

/**
 * 000 => Error
 * 010 => Uninitialized
 * 100 => Initialized
 * @param status
 * @returns {{payload: *, type}}
 */
export const setAppState = status => {
  return {
    payload: status,
    type: types.SET_APP_STATE
  };
};

export const setAppLocale = locale => {
  return {
    payload: locale,
    type: types.SET_APP_LOCALE
  };
};

export const setAppRouteData = (data = { resolved: false, path: "" }) => {
  return {
    payload: fromJS(data),
    type: types.SET_APP_ROUTE_DATA
  };
};

// {
//   type: "SET_OPTION_LIST",
//   payload(action, state, res) {
//     console.log(res);
//     return action;
//   }
// },
// {
//   type: "SET_REGISTER_DATA",
//   payload(action, state, res) {
//     console.log("payload:", res);
//     return action;
//   },
//   meta: (action, state, res) => {
//     console.log("meta:", action);
//     return action;
//   }
// }

export const loadOptionList = () => {
  return buildRSA(
    {
      endpoint: "/options",
      method: "GET",
      types: ["LOAD_OPTION_LIST", "SET_OPTION_LIST", "SET_API_ERROR"]
    },
    true
  );
};

export const loadAccountTypes = () => {
  return {
    [RSAA]: {
      endpoint: `${baseApIURL}/account-types`,
      method: "GET",
      types: [
        types.LOAD_ACCOUNT_TYPES,
        types.SET_ACCOUNT_TYPES,
        types.SET_API_ERROR
      ]
    }
  };
};

export const loadRoles = () => {
  return {
    [RSAA]: {
      endpoint: `${baseApIURL}/roles`,
      method: "GET",
      types: [types.LOAD_ROLES, types.SET_ROLES, types.SET_API_ERROR]
    }
  };
};

export default {
  setAppState,
  setAppLocale,
  setAppRouteData,
  loadOptionList,
  loadAccountTypes,
  loadRoles
};
