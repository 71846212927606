import { combineReducers } from "redux-immutable";

import app from "./app.js";
import auth from "./auth.js";
import users from "./users";
import reg from "./reg.js";
import photos from "./photos";
import sitePosts from "./sitePosts";
import discussions from "./discussions";
import writings from "./writings";
import media from "./media";
import categories from "./categories";
import roles from "./roles";
import comments from "./comments";
import conversations from "./conversations";
import messages from "./messages";
import subscriptions from "./subscriptions";
import tags from "./tags";
import reports from "./reports";
import promoCodes from "./promoCodes";
import emailNotifications from "./emailNotifications";

import currentUser from "./currentUser";
import user from "./user";
import conversationList from "./conversationList";
import chatWindow from "./chatWindow";
import chat from "./chat";

import plusBooks from "./plusBooks";

import bannedMessage from "./bannedMessage";
import inquiry from "./inquiry";
import vbPabConnect from "./vbPabConnect.js";
import vbPabProdTag from "./vbPabProdTag.js";
import pabAcadleTag from "./pabAcadleTag";
import bannedProfileDet from "./bannedProfileDet";
import bannedUsernameChar from "./bannedUsernameChar";

import navEvents from "./navEvents";
import navCommunities from "./navCommunities";
import navBooks from "./navBooks";

import upcoachUsersToDel from "./upcoachUsersToDel";

// BOundariesJournal
import dailyQs from "./Boundaries/DailyQs.js";
import boundariesUsers from "./Boundaries/Users.js"
import boundUser from "./Boundaries/BoundUser.js"
import boundReports from "./Boundaries/Reports.js"
import qResponses from "./Boundaries/QResponses.js";
import userResponses from "./Boundaries/UserResponses.js";
//

const reducers = {
  app,
  auth,
  photos,
  sitePosts,
  media,
  discussions,
  writings,
  categories,
  roles,
  comments,
  messages,
  conversations,
  subscriptions,
  tags,
  user,
  users,
  reports,
  promoCodes,
  emailNotifications,

  reg,
  currentUser,
  conversationList,
  chatWindow,

  chat,
  plusBooks,
  bannedMessage,
  inquiry,
  vbPabConnect,
  vbPabProdTag,
  pabAcadleTag,
  bannedProfileDet,
  bannedUsernameChar,

  navEvents,
  navBooks,
  navCommunities,

  upcoachUsersToDel,

// Boundaries Journal

  boundReports,
  boundariesUsers,
  boundUser,
  dailyQs,
  qResponses,
  userResponses

  //
};

export default combineReducers(reducers);
